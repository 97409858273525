<template>
  <div class="institutions">
    <div class="banner">
      <div class="content">
        <div class="title" data-aos="fade-up">{{$t('institutions.bannerText')}}</div>
      </div>
    </div>
    <div class="bannermb">
      <div class="content">
        <div class="title" data-aos="fade-up">{{$t('institutions.bannerText')}}</div>
      </div>
    </div>
    <div class="main">
      <!-- <div class="left" @click="slidePrev()">
        <img class="img" src="@/assets/icon_left@2x.png" alt="">
      </div> -->
      <div class="mySwiper">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item,index) in organdata" :key="index">
            <div class="imgBox" :data-aos="index%2 == 0?'fade-up':'fade-down'" data-aos-duration="500">
              <img class="img" :src="baseHostUrl + item.imgurl" :alt="item.title">
            </div>
          </swiper-slide>
        </swiper>
      </div>
      
      <!-- <div class="right"  @click="slideNext()">
        <img class="img" src="@/assets/icon_rest@2x.png" alt="">
      </div> -->
    </div>
    <div class="mainMB">
      <!-- <div class="left" @click="slidePrev()">
        <img class="img" src="@/assets/icon_left@2x.png" alt="">
      </div> -->
      <div class="mySwiper">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide>
            <div class="imgBox" v-for="(item,index) in organdata" :key="index" :data-aos="index%2 == 0?'fade-up':'fade-down'" data-aos-duration="500">
              <img class="img" :src="baseHostUrl + item.imgurl" :alt="item.title">
            </div>
          </swiper-slide>
        </swiper>
      </div>
      
      <!-- <div class="right"  @click="slideNext()">
        <img class="img" src="@/assets/icon_rest@2x.png" alt="">
      </div> -->
    </div>
  </div>
</template>
<script>
import {baseHostUrl,getorgandata} from '@/utils/index.js'
export default {
  name: 'institutions',
  components: {

  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      baseHostUrl: '',
      organdata: {}
    }
  },
  computed: {
  },
  created() {
    if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      this.swiperOptions.slidesPerView = 1
      this.swiperOptions.spaceBetween = 0
    }else{
      this.swiperOptions.slidesPerView = 3
      this.swiperOptions.spaceBetween = 30
    }
  },
  mounted() {
		document.title = '云脉芯联';
    this.getorgandata()
    this.baseHostUrl = baseHostUrl()
  },
  methods: {
    async getorgandata() {
      let that = this
      let data = {
        type: localStorage.getItem('language').toLowerCase()
      }
      let res = await getorgandata(data)
      that.organdata = res.data
    },
    slideNext() {
      this.$refs.mySwiper.$swiper.slideNext()
    },
    slidePrev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
  background: url('../assets/banner.png') no-repeat;
  height: 31vw;
  background-size: cover;
  display: flex;
  align-items: center;
  .content{
    width: 63vw;
    margin: auto;
    display: flex;
    align-items: center;
    .title{
      font-size: 1.8vw;
      font-weight: 700;
      color: #FFFFFF;
    }
  }
}
.bannermb{
  display: none;
}
.main{
  background: url('../assets/bg@2x.png') no-repeat;
  height: 40vw;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .mySwiper{
    width: 63vw;
    overflow: hidden;
    .imgBox{
      .img{
        height: 4.2vw;
        margin: auto;
      }
    }
  }
  .left{
    background: #EDEEF0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5vw 0.8vw;
    border-radius: 0.2vw;
    cursor: pointer;
    margin-right: 2vw;
    .img{
      width: 1vw;
    }
  }
  .right{
    background: #EDEEF0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5vw 0.8vw;
    border-radius: 0.2vw;
    cursor: pointer;
    margin-left: 2vw;
    .img{
      width: 1vw;
    }
  }
}
.mainMB{
  display: none;
}
@media screen and (max-width:750px){
  .banner{
    display: none;
  }
  .bannermb{
    background: url('../assets/bannermb.png') no-repeat;
    height: 67vw;
    background-size: cover;
    display: flex;
    align-items: center;
    .content{
      display: flex;
      align-items: center;
      padding: 6vw;
      flex: 1;
      .title{
        font-size: 7vw;
        font-weight: 700;
        color: #FFFFFF;
      }
    }
  }
  .main{
    display: none;
  }
  .mainMB{
    background: url('../assets/bg@2xmb.png') no-repeat;
    height: 106vw;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .mySwiper{
      width: 100%;
      overflow: hidden;
      .imgBox{
        .img{
          height: 12vw;
          margin: auto auto 11vw auto;
        }
        &:last-child{
          .img{
          height: 12vw;
          margin: auto auto 0 auto;
        }
        }
      }
    }
    .left{
      background: #EDEEF0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5vw 0.8vw;
      border-radius: 0.2vw;
      cursor: pointer;
      margin-right: 2vw;
      .img{
        width: 1vw;
      }
    }
    .right{
      background: #EDEEF0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5vw 0.8vw;
      border-radius: 0.2vw;
      cursor: pointer;
      margin-left: 2vw;
      .img{
        width: 1vw;
      }
    }
  }
}
</style>